/* Reset */

html{line-height:1.15;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;box-sizing:border-box}*,::after,::before{box-sizing:inherit}body{margin:0}main{display:block}blockquote,body,figure,h1,h2,h3,h4,h5,h6,hr,li,ol,p,pre,ul{margin:0;padding:0}h1,h2,h3,h4,h5,h6,p{margin:1em 0}a{background-color:transparent}b,strong{font-weight:bolder}audio,embed,iframe,img,object,video{display:block;max-width:100%}img{border-style:none}button,input,optgroup,select,textarea{font-family:inherit;font-size:100%;line-height:1.15;margin:0}button,input{overflow:visible}button,select{text-transform:none}[type=button],[type=reset],[type=submit],button{-webkit-appearance:button}[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner,button::-moz-focus-inner{border-style:none;padding:0}[type=button]:-moz-focusring,[type=reset]:-moz-focusring,[type=submit]:-moz-focusring,button:-moz-focusring{outline:1px dotted ButtonText}fieldset{padding:.35em .75em .625em}progress{vertical-align:baseline}textarea{overflow:auto}[type=checkbox],[type=radio]{box-sizing:border-box;padding:0}[type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button{height:auto}[type=search]{-webkit-appearance:textfield;outline-offset:-2px}[type=search]::-webkit-search-decoration{-webkit-appearance:none}::-webkit-file-upload-button{-webkit-appearance:button;font:inherit}

/* Media */

@mixin media($size) {
	@if $size == 'laptop' {
		@media screen and (min-width:1201px) and (max-width:1440px) {
			@content;
		}
	} @else if $size == 'tablet' {
		@media screen and (min-width:768px) and (max-width:1200px) {
			@content;
		}
	} @else if $size == 'tablet-landscape' {
		@media screen and (min-width:1024px) and (max-width:1200px) {
			@content;
		}
	} @else if $size == 'tablet-portrait' {
		@media screen and (min-width:768px) and (max-width:1023px) {
			@content;
		}
	} @else if $size == 'tablet-and-phone' {
		@media screen and (max-width: 1023px) {
			@content;
		}
	} @else if $size == 'phone' {
		@media screen and (max-width: 767px) {
			@content;
		}
	} @else if $size == 'print' {
		@media print {
			@content;
		}
	}
}

/* Resets */

@mixin listReset {
	list-style: none;
	margin: 0;
	padding: 0;
}
@mixin inputReset($autofill: #000000) {
	background: none;
	padding: 0;
	margin: 0;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	resize: none;
	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}
	&::-webkit-search-decoration,
	&::-webkit-search-cancel-button,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration {
		display: none;
	}
	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		transition: background-color 443556s ease-in-out 0s;
		-webkit-text-fill-color: $autofill !important;
	}
	&:-moz-focusring {
    	color: transparent;
    	text-shadow: 0 0 0 #000;
	}
	&::-moz-focus-outer {
    	border: 0;
	}
}
@mixin placeholder {
	&::-webkit-input-placeholder {
		@content;
	}
	&:-moz-placeholder {
		@content;
	}
	&::-moz-placeholder {
		@content;
	}
	&:-ms-input-placeholder {
		@content;
	}
}
@mixin globalSelect {
    ::-moz-selection { @content; }
    ::selection { @content; }
}
@mixin fontSmoothing {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
@mixin columnsAvoidBreak {
	-webkit-column-break-inside: avoid;
	page-break-inside: avoid;
	break-inside: avoid-column;
}
@mixin inputRangeThumb {
	@include inputReset;
	//background-image: url('../img/symbol-line.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 20px 40px;
	height: 20px;
	width: 20px;
	cursor: grab;
}

/* Layout */

$defaultGridColumns: 18;
$defaultFlexColumns: 12;
$defaultGutter: 50px;

$defaultBreakpoints: (
	'xs': 'screen and (max-width: 767px)',
	'sm': 'screen and (min-width:768px)',
	'md': 'screen and (min-width:1024px)',
	'lg': 'screen and (min-width:1201px)'
);
$defaultBreakpointsVisibility: (
	'xs': 'screen and (max-width: 767px)',
	'sm': 'screen and (min-width:768px) and (max-width:1023px)',
	'md': 'screen and (min-width:1024px) and (max-width:1200px)',
	'lg': 'screen and (min-width:1201px) and (max-width:1440px)',
	'print': 'print'
);

/* Flex */

// @mixin flexColumns($breakpoint) {
// 	@for $i from 1 through $defaultFlexColumns {
// 	    .span\:#{$i}\@#{$breakpoint} {
// 			flex: 0 0 $i/$defaultFlexColumns * 100%;
// 			max-width: $i/$defaultFlexColumns * 100%;
// 	    }
// 		@if $i == 1 {
// 			.span-ml\:0\@#{$breakpoint} {
// 				margin-left: 0;
// 			}
// 			.span-mr\:0\@#{$breakpoint} {
// 				margin-right: 0;
// 			}
// 			.span-dr\:0\@#{$breakpoint} {
// 				left: 0;
// 			}
// 			.span-dl\:0\@#{$breakpoint} {
// 				right: 0;
// 			}
// 			.span-push\:0\@#{$breakpoint} {
// 				left: 0;
// 			}
// 			.span-pull\:0\@#{$breakpoint} {
// 				right: 0;
// 			}
// 		}
// 		.span-ml\:#{$i}\@#{$breakpoint} {
// 			margin-left: $i/$defaultFlexColumns * 100%;
// 		}
// 	    .span-mr\:#{$i}\@#{$breakpoint} {
// 	        margin-right: $i/$defaultFlexColumns * 100%;
// 	    }
// 		.span-dr\:#{$i}\@#{$breakpoint} {
// 			//left: calc(((#{$i}/#{$defaultFlexColumns} * 100vw) - (#{$i}/#{$defaultFlexColumns}) * 100vw) + (#{$defaultGutter} * 2));
// 			left: calc(#{$defaultGutter} * 2);
// 			.--reset {
// 				padding-right: calc(#{$defaultGutter} * 2);
// 			}
// 		}
// 		.span-dl\:#{$i}\@#{$breakpoint} {
// 			//right: calc(((#{$i}/#{$defaultFlexColumns} * 100vw) - (#{$i}/#{$defaultFlexColumns}) * 100vw) + (#{$defaultGutter} * 2));
// 			right: calc(#{$defaultGutter} * 2);
// 			.--reset {
// 				padding-left: calc(#{$defaultGutter} * 2);
// 			}
// 		}
// 		.span-push\:#{$i}\@#{$breakpoint} {
// 	        left: $i/$defaultFlexColumns * 100%;
// 	    }
// 	    .span-pull\:#{$i}\@#{$breakpoint} {
// 	        right: $i/$defaultFlexColumns * 100%;
// 	    }
// 	}
// }
// .flex {
// 	width: 100%;
// 	margin: 0 auto;
// 	padding: 0 25px;
// 	&.--max {
// 		max-width: 1440px;
// 	}
// 	.wrap {
// 		display: flex;
//     	flex-flow: row wrap;
// 		margin: 0 -40px;
// 		*[class*="span:"] {
// 			width: 100%;
// 			padding: 0 40px;
// 			position: relative;
// 		}
// 		@each $breakpoint, $query in $defaultBreakpoints {
// 			@if $breakpoint == 'xs' {
// 			  @include flexColumns(#{$breakpoint})
// 			} @else {
// 				@media #{$query} {
// 					@include flexColumns(#{$breakpoint})
// 				}
// 			}
// 		}
// 	}
// }

/* Grid */

// @mixin gridColumnSpan($breakpoint) {
// 	@for $i from 1 through $defaultFlexColumns {
// 	    .col-span\:#{$i}\@#{$breakpoint} {
// 			grid-column-start: span $i;
// 	    }
// 	}
// }
// @mixin gridColumnStartEnd($breakpoint) {
// 	@for $a from 1 through $defaultFlexColumns + 1 {
// 		@for $b from 1 through $defaultFlexColumns + 1 {
// 			.col-ab\:#{$a}\/#{$b}\@#{$breakpoint} {
// 				grid-column: #{$a}/#{$b};
// 			}
// 		}
// 	}
// }
// .grid {
// 	display: grid;
// 	&.--span {
//     	grid-template-columns: repeat($defaultFlexColumns, 1fr);
// 		@each $breakpoint, $query in $defaultBreakpoints {
// 			@if $breakpoint == 'xs' {
// 	          @include gridColumnSpan(#{$breakpoint})
// 	        } @else {
// 	        	@media #{$query} {
// 	          		@include gridColumnSpan(#{$breakpoint})
// 				}
// 	        }
// 		}
// 	}
// 	&.--ab {
//     	grid-template-columns: repeat($defaultFlexColumns, 1fr);
// 		@each $breakpoint, $query in $defaultBreakpoints {
// 			@if $breakpoint == 'xs' {
// 	          @include gridColumnStartEnd(#{$breakpoint})
// 	        } @else {
// 	        	@media #{$query} {
// 	          		@include gridColumnStartEnd(#{$breakpoint})
// 				}
// 	        }
// 		}
// 	}
// }
//
// @mixin gridHelpers($breakpoint) {
// 	@for $space from 1 through 20 {
// 		.grid-tc\:#{$space}\@#{$breakpoint} {
// 			grid-template-columns: repeat(#{$space}, 1fr) !important;
// 		}
// 	}
// 	@for $space from 1 through 500 {
// 		.grid-cg\:#{$space}\@#{$breakpoint} {
// 			grid-column-gap: #{$space}px !important;
// 		}
// 		.grid-rg\:#{$space}\@#{$breakpoint} {
// 			grid-row-gap: #{$space}px !important;
// 		}
// 	}
// }
// @each $breakpoint, $query in $defaultBreakpoints {
// 	@if $breakpoint == 'xs' {
// 	  @include gridHelpers(#{$breakpoint})
// 	} @else {
// 		@media #{$query} {
// 			@include gridHelpers(#{$breakpoint})
// 		}
// 	}
// }

/* Helper classes */

.d\:b {
	display: block;
}
.d\:f {
	display: flex;
}
.d\:if {
	display: inline-flex;
}
.d\:i {
	display: inline;
}
.d\:ib {
	display: inline-block;
}
.d\:g {
	display: grid;
}
.d\:n {
	display: none;
}
@each $breakpoint, $query in $defaultBreakpointsVisibility {
	.show\@#{$breakpoint},
	.show\:f\@#{$breakpoint},
	.show\:if\@#{$breakpoint},
	.show\:i\@#{$breakpoint},
	.show\:ib\@#{$breakpoint},
	.show\:g\@#{$breakpoint} {
		display: none !important;
	}
}
@each $breakpoint, $query in $defaultBreakpointsVisibility {
	@media #{$query} {
		.show\@#{$breakpoint} {
			display: block !important;
		}
		.show\:f\@#{$breakpoint} {
			display: flex !important;
		}
		.show\:if\@#{$breakpoint} {
			display: inline-flex !important;
		}
		.show\:i\@#{$breakpoint} {
			display: inline !important;
		}
		.show\:ib\@#{$breakpoint} {
			display: inline-block !important;
		}
		.show\:g\@#{$breakpoint} {
			display: grid !important;
		}
		.hide\@#{$breakpoint} {
			display: none !important;
		}
	}
}

$sizes: (1, 2);
@mixin fontHelpers($breakpoint) {
	@each $size in $sizes {
		.fs\:#{$size}\@#{$breakpoint} {
			font-size: #{$size}px !important;
		}
	}
}
@each $breakpoint, $query in $defaultBreakpoints {
	@if $breakpoint == 'xs' {
	  @include fontHelpers(#{$breakpoint})
	} @else {
		@media #{$query} {
			@include fontHelpers(#{$breakpoint})
		}
	}
}

$spaces: (1, 2);
$sides: (top, bottom, left, right);
@mixin spaceHelpers($breakpoint) {
	@each $space in $spaces {
		@each $side in $sides {
			.m#{str-slice($side, 0, 1)}\:#{$space}\@#{$breakpoint} {
				margin-#{$side}: #{$space}px !important;
			}
			.p#{str-slice($side, 0, 1)}\:#{$space}\@#{$breakpoint} {
				padding-#{$side}: #{$space}px !important;
			}
			.#{str-slice($side, 0, 1)}\:#{$space}\@#{$breakpoint} {
				#{$side}: #{$space}px !important;
			}
		}
	}
}
@each $breakpoint, $query in $defaultBreakpoints {
	@if $breakpoint == 'xs' {
	  @include spaceHelpers(#{$breakpoint})
	} @else {
		@media #{$query} {
			@include spaceHelpers(#{$breakpoint})
		}
	}
}

$positions: ('relative', 'absolute', 'fixed', 'sticky');
$flexPositions: ('flex-start', 'center', 'flex-end', 'space-around', 'space-between', 'space-evenly');
@mixin positionHelpers($breakpoint) {
	@each $position in $positions {
		.p\:#{str-slice($position, 0, 1)}\@#{$breakpoint} {
			position: #{$position} !important;
		}
	}
	@each $position in $flexPositions {
		@if $position == 'flex-start' {
			.ac\:fs\@#{$breakpoint} {
				align-content: #{$position} !important;
			}
			.ai\:fs\@#{$breakpoint} {
				align-items: #{$position} !important;
			}
			.jc\:fs\@#{$breakpoint} {
				justify-content: #{$position} !important;
			}
		} @elseif $position == 'flex-end' {
			.ac\:fe\@#{$breakpoint} {
				align-content: #{$position} !important;
			}
			.ai\:fe\@#{$breakpoint} {
				align-items: #{$position} !important;
			}
			.jc\:fe\@#{$breakpoint} {
				justify-content: #{$position} !important;
			}
		} @elseif $position == 'space-around' {
			.ac\:sa\@#{$breakpoint} {
				align-content: #{$position} !important;
			}
			.jc\:sa\@#{$breakpoint} {
				justify-content: #{$position} !important;
			}
		} @elseif $position == 'space-between' {
			.ac\:sb\@#{$breakpoint} {
				align-content: #{$position} !important;
			}
			.jc\:sb\@#{$breakpoint} {
				justify-content: #{$position} !important;
			}
		} @elseif $position == 'space-evenly' {
			.ac\:se\@#{$breakpoint} {
				align-content: #{$position} !important;
			}
			.jc\:se\@#{$breakpoint} {
				justify-content: #{$position} !important;
			}
		} @else {
			.ac\:#{str-slice($position, 0, 1)}\@#{$breakpoint} {
				align-content: #{$position} !important;
			}
			.ai\:#{str-slice($position, 0, 1)}\@#{$breakpoint} {
				align-items: #{$position} !important;
			}
			.jc\:#{str-slice($position, 0, 1)}\@#{$breakpoint} {
				justify-content: #{$position} !important;
			}
		}
	}
}
@each $breakpoint, $query in $defaultBreakpoints {
	@if $breakpoint == 'xs' {
	  @include positionHelpers(#{$breakpoint})
	} @else {
		@media #{$query} {
			@include positionHelpers(#{$breakpoint})
		}
	}
}

$textAlignments: ('left', 'center', 'right');
$textTransforms: ('uppercase', 'lowercase', 'none');
@mixin textHelpers($breakpoint) {
	@each $textAlignment in $textAlignments {
		.ta\:#{str-slice($textAlignment, 0, 1)}\@#{$breakpoint} {
			text-align: #{$textAlignment} !important;
		}
	}
	@each $textTransform in $textTransforms {
		.tt\:#{str-slice($textTransform, 0, 1)}\@#{$breakpoint} {
			text-transform: #{$textTransform} !important;
		}
	}
}
@each $breakpoint, $query in $defaultBreakpoints {
	@if $breakpoint == 'xs' {
	  @include textHelpers(#{$breakpoint})
	} @else {
		@media #{$query} {
			@include textHelpers(#{$breakpoint})
		}
	}
}
