@import 'setup';

/* Webfonts */

@font-face {
    font-family: 'Soehne';
    src: url('../webfonts/soehne-web-halbfett.woff2') format('woff2'), url('../webfonts/soehne-web-halbfett.woff') format('woff');
    font-style: normal;
    font-weight: 600;
}
@font-face {
    font-family: 'Soehne';
    src: url('../webfonts/soehne-web-fett.woff2') format('woff2'), url('../webfonts/soehne-web-fett.woff') format('woff');
    font-style: normal;
    font-weight: 800;
}
@font-face {
    font-family: 'Signifier';
    src: url('../webfonts/signifier-web-light.woff2') format('woff2'), url('../webfonts/signifier-web-light.woff') format('woff');
    font-style: normal;
    font-weight: 300;
}
@font-face {
    font-family: 'Signifier';
    src: url('../webfonts/signifier-web-light-italic.woff2') format('woff2'), url('../webfonts/signifier-web-light-italic.woff') format('woff');
    font-style: italic;
    font-weight: 300;
}

/* Vars */

:root {
	--colorBody: #000000;
}

/* Global */

* {
	@include fontSmoothing;
}
body {
	font-family: 'Signifier', sans-serif;
	background-color: #ffffff;
	color: #000000;
	font-size: 16px;
	line-height: 1.2;
}
a {
	transition: color 250ms;
}
a, a:active, a:focus, a:hover {
	color: #000000;
	outline: none;
	text-decoration: none;
}
h1, h2, h3, h4, h5, h6 {
	font-weight: normal;
	line-height: 1.2em;
}
.--loading,
.--loading * {
    cursor: wait !important;
}
.--lazy {
	min-height: 1px;
	min-width: 1px;
	opacity: 0;
    transition: opacity 250ms;
    -webkit-backface-visibility: hidden;
}
.--lazy.--loaded {
    opacity: 1;
}
[data-ratio] {
	display: block;
	position: relative;
	width: 100%;
	background-color: #f7f7f7;
	&.--transparent {
		background-color: transparent;
	}
}
[data-ratio] > img {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
[x-cloak] {
	display: none !important;
}
@include globalSelect {
    color: #000000;
    background-color: #ffff00;
}
@media screen and (orientation: landscape) {
	[data-orientation="landscape"] {
		display: block;
	}
	[data-orientation="portrait"] {
		display: none;
	}
}
@media screen and (orientation: portrait) {
	[data-orientation="landscape"] {
		display: none;
	}
	[data-orientation="portrait"] {
		display: block;
	}
}
div.page {
	> div {
		margin: calc(100vh/3) 0;
		@include media('tablet-portrait') {
			margin: calc((100vh/12) * 5) 0;
		}
		@at-root body.home #{&} {
			margin: 0;
		}
		@at-root body.projects-single #{&} {
			margin: 190px 0 50px 0;
			@include media('tablet-portrait') {
				margin: calc((100vh/12) * 4) 0 30px 0;
			}
			@include media('phone') {
				margin: 190px 0 20px 0;
			}
		}
		@at-root body.contact #{&}, body.about #{&} {
			margin-bottom: 0;
		}
	}
}
.text {
	&.--flex {
		> * {
			margin: 0.5em 0;
		}
	}
	> *:first-child {
		margin-top: 0 !important;
	}
	> *:last-child {
		margin-bottom: 0 !important;
	}
	h4 {
		font-family: 'Soehne', sans-serif;
		font-size: 22px;
		font-weight: 800;
		line-height: 1.1;
		letter-spacing: -0.035em;
		display: inline-flex;
		flex-flow: row wrap;
		align-items: baseline;
		margin-bottom: 60px;
		@include media('phone') {
			font-size: 18px;
			margin-bottom: 30px;
		}
		&:before {
			content: '';
			display: block;
			width: 20vw;
			max-width: 200px;
			height: 1px;
			background-color: #707070;
			margin-right: 10px;
		}
		& + p {
			margin-top: 0;
		}
	}
}
div.site__overlay {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 70;
	visibility: hidden;
	backdrop-filter: blur(40px);
	&.--active {
		visibility: visible;
	}
}

/* Cursor */

div.cursor {
	position: fixed;
	top: 0;
	left: 0;
	pointer-events: none;
	z-index: 666;
	@media (hover: none) {
		display: none !important;
	}
	span {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		&.cursor__slider {
			&.--horizontal {
				height: 45px;
				width: 104px;
			}
			svg {
				fill: black;
			}
		}
		svg {
			height: auto;
			max-height: 100%;
			width: auto;
			max-width: 100%;
		}
	}
}

/* Header */

a.site__title {
	font-family: 'Soehne', sans-serif;
	font-size: clamp(80px, 9.5vw, 190px); // min, value, max
	font-weight: 800;
	letter-spacing: -0.035em;
	line-height: 0.9;
	color: black;
	margin: 0;
	position: fixed;
	top: -0.13em;
	left: 0;
	z-index: 100;
	transition: none;
	@include media('tablet-landscape') {
		font-size: clamp(80px, 13vw, 190px); // min, value, max
	}
	@include media('tablet-portrait') {
		font-size: clamp(80px, 20vw, 190px); // min, value, max
	}
	@at-root body.home #{&} {
		color: white;
		mix-blend-mode: difference;
	}
	&.--reset {
		@at-root body.home #{&} {
			color: black;
			mix-blend-mode: normal;
		}
	}
	span {
		@at-root body.home #{&} {
			visibility: hidden;
		}
	}
}
button.nav__trigger {
	@include inputReset;
	display: block;
	height: 57px;
	width: 57px;
	background-color: black;
	border-radius: 50%;
	position: fixed;
	top: 30px;
	right: 30px;
	cursor: pointer;
	z-index: 100;
	@include media('phone') {
		height: 35px;
		width: 35px;
		top: 20px;
		right: 20px;
		transform: none;
	}
	&.--reset {
		@at-root body.home #{&} {
			background-color: black;
			mix-blend-mode: normal;
		}
		span {
			@at-root body.home #{&} {
				background-color: white;
			}
		}
	}
	&.--loaded {
		@at-root body.home #{&} {
			z-index: 100;
		}
	}
	&.--inactive {
		pointer-events: none;
	}
	@at-root body.home #{&} {
		z-index: 80;
		background-color: white;
		mix-blend-mode: difference;
	}
	span {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: block;
		height: 6px;
		width: calc(100% - 20px);
		background-color: white;
		@include media('phone') {
			height: 3px;
			width: calc(100% - 13px);
		}
		@at-root body.home #{&} {
			background-color: black;
		}
		&:first-child {
			transform: translate(-50%, -50%) translateY(-6px);
			@include media('phone') {
				transform: translate(-50%, -50%) translateY(-4px)
			}
		}
		&:last-child {
			transform: translate(-50%, -50%) translateY(6px);
			@include media('phone') {
				transform: translate(-50%, -50%) translateY(3px);
			}
		}
	}
	&.--open {
		span {
			&:first-child {
				transform: translate(-50%, -50%) rotate(45deg);
			}
			&:last-child {
				transform: translate(-50%, -50%) rotate(-45deg);
			}
		}
	}
}

/* Nav */

nav.main {
	@include listReset;
	position: fixed;
	top: 0;
	right: 0;
	padding: 30px;
	height: 100vh;
	height: calc((var(--vh, 1vh) * 100));
	width: 100%;
	z-index: 75;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-end;
	align-items: flex-end;
	ul {
		@include listReset;
		li {
			@include listReset;
			font-family: 'Soehne', sans-serif;
			font-size: clamp(69px, 7.5vw, 150px); // min, value, max
			font-weight: 800;
			line-height: 1.1;
			letter-spacing: -0.035em;
			visibility: hidden;
			@include media('tablet-landscape') {
				font-size: clamp(69px, 10vw, 150px); // min, value, max
			}
			@include media('tablet-portrait') {
				font-size: clamp(69px, 16vw, 150px); // min, value, max
			}
		}
	}
}

/* Home */

div.home__loader {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: white;
	z-index: 90;
}
div.home__layout {
	> div {
		position: relative;
		&.--top {
			height: 100vh;
			height: calc((var(--vh, 1vh) * 100));
			background-color: white;
		}
		&.--bottom {
			background-color: white;
			padding: 25vh 30px 100px 30px;
			@include media('tablet-portrait') {
				padding: 100px 30px;
			}
			@include media('phone') {
				padding: 60px 20px 100px 20px;
			}
			div.text {
				font-size: 68px;
				text-indent: 100px;
				position: relative;
				@include media('tablet') {
					font-size: 48px;
				}
				@include media('phone') {
					font-size: 28px;
					text-indent: 60px;
				}
				&:before {
					content: 'Hi*';
					position: absolute;
					top: 10px;
					left: -100px;
					font-family: 'Soehne', sans-serif;
					font-size: 22px;
					font-weight: 800;
					letter-spacing: -0.035em;
					@include media('tablet') {
						font-size: 22px;
						top: 4px;
					}
					@include media('phone') {
						font-size: 18px;
						top: 3px;
						left: -60px;
					}
				}
			}
		}
	}
}

/* Contact */

div.contact__layout {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 100px 60px;
	padding: 0 30px;
	@include media('phone') {
		grid-template-columns: 1fr;
		grid-gap: 60px 20px;
		padding: 0 20px;
	}
	> div {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 100px 60px;
		@include media('phone') {
			grid-gap: 60px 20px;
		}
		div.text {
			font-size: 48px;
			@include media('tablet-landscape') {
				font-size: 22px;
			}
			@include media('tablet-and-phone') {
				font-size: 22px;
			}
		}
	}
}
div.map__layout {
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin-top: 100px;
	@include media('phone') {
		grid-template-columns: 1fr;
		margin-top: 60px;
	}
	div.map__canvas {
		height: 0;
		padding-bottom: 100%;
	}
	img {
		object-fit: cover;
	}
}

/* Footer */

footer {
	background-color: white;
	height: 50vh;
	position: relative;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-end;
	padding: 30px;
	@include media('phone') {
		padding: 20px;
	}
	ul.social__list {
		@include listReset;
		display: flex;
		flex-flow: row wrap;
		@include media('tablet-and-phone') {
			flex-flow: column nowrap;
		}
		li {
			@include listReset;
			font-family: 'Soehne', sans-serif;
			font-size: clamp(45px, 6vw, 110px); // min, value, max
			font-weight: 800;
			letter-spacing: -0.035em;
			&:not(:last-child) {
				margin-right: 0.5em;
				@include media('phone') {
					margin-right: 0;
				}
			}
			a {
				color: black;
			}
		}
	}
}

/* Projects */

nav.categories {
	position: fixed;
	top: 30px;
	right: calc(30px + 57px + 30px);
	z-index: 65;
	@include media('tablet') {
		position: relative;
		right: 0;
		top: 0;
	    width: calc(50% + 15px);
		padding: 0 30px;
		margin-bottom: 30px;
	}
	@include media('phone') {
		position: relative;
		right: 0;
		top: 0;
		padding: 0 20px;
		margin-bottom: 20px;
	}
	&.--active {
		z-index: 80;
		> button {
			&:after {
				transform: translateY(-50%) rotate(180deg);
			}
		}
	}
	> button {
		@include inputReset;
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		height: 57px;
		font-family: 'Soehne', sans-serif;
		font-size: 38px;
		font-weight: 600;
		position: relative;
		width: 100%;
		min-width: 280px;
		padding-right: 100px;
		border-bottom: 2px solid black;
		cursor: pointer;
		@include media('tablet') {
			font-size: 28px;
		}
		@include media('phone') {
			height: 50px;
			font-size: 22px;
		}
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			display: block;
			height: 0;
			width: 0;
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
			border-top: 13px solid black;
		}
	}
	ul {
		@include listReset;
		@include media('phone') {
			position: absolute;
			left: 0;
			width: 100%;
			padding: 0 20px;
		}
		li {
			@include listReset;
			font-family: 'Soehne', sans-serif;
			font-size: 38px;
			font-weight: 600;
			padding: 5px 0;
			min-width: 280px;
			@include media('tablet') {
				font-size: 28px;
			}
			@include media('phone') {
				font-size: 22px;
			}
		}
	}
}
div.projects__layout {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 100px 30px;
	padding: 0 30px;
	@include media('tablet') {
		grid-template-columns: repeat(2, 1fr);
	}
	@include media('phone') {
		grid-template-columns: 1fr;
		grid-gap: 20px 20px;
		padding: 0 20px;
	}
	&.--filtered {
		> div.project {
			&.--doubled {
				grid-column: span 1;
			}
		}
	}
	> div.project {
		&[data-alignment-y="bottom"] {
			align-self: end;
		}
		&.--doubled {
			grid-column: span 2;
			@include media('tablet') {
				grid-column: span 1;
			}
			@include media('phone') {
				grid-column: span 1;
			}
		}
		div.title__categories {
			h1 {
				font-size: 28px;
				margin: 0;
				@include media('tablet-and-phone') {
					font-size: 22px;
				}
			}
			ul {
				@include listReset;
				display: flex;
				flex-flow: row wrap;
				li {
					@include listReset;
					font-family: 'Soehne', sans-serif;
					font-size: 22px;
					font-weight: 600;
					&:not(:last-child) {
						&:after {
							content: '/';
							display: inline-flex;
							margin: 0 5px;
						}
					}
				}
			}
		}
		a.media {
			display: block;
			margin-top: 15px;
		}
	}
}
div.project__layout {
	div.title__info {
		background-color: white;
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		height: calc(38px + (20px * 2));
		padding: 0 30px;
		&.--reset {
			background-color: transparent;
			button.info__trigger {
				position: relative;
				z-index: 80;
			}
		}
		@include media('tablet-portrait') {
			flex-flow: row wrap;
			height: auto;
		}
		@include media('phone') {
			flex-flow: row wrap;
			height: auto;
			padding: 0 20px;
		}
		button.info__trigger {
			@include inputReset;
			font-size: 28px;
			cursor: pointer;
			@include media('tablet-and-phone') {
				font-size: 22px;
				margin: 10px 0;
			}
		}
		h1 {
			font-size: 28px;
			margin: 0;
			width: 50%;
			text-align: center;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			@include media('tablet-and-phone') {
				font-size: 22px;
				width: 100%;
				text-align: left;
				white-space: normal;
				text-overflow: inherit;
				position: relative;
				left: 0;
				transform: none;
			}
		}
		div.slider__counter {
			font-size: 28px;
		}
	}
	div.info__layout {
		padding: 30px;
		position: absolute;
		height: calc(100vh - (190px + ((38px + (20px * 2)))));
		height: calc((var(--vh, 1vh) * 100) - (190px + ((38px + (20px * 2)))));
		width: 100%;
		overflow-y: auto;
		z-index: 80;
		@include media('tablet-portrait') {
			height: auto;
		}
		@include media('phone') {
			padding: 20px;
		}
		div.text {
			width: 50%;
			@include media('tablet-landscape') {
				width: 75%;
			}
			@include media('tablet-portrait') {
				width: 100%;
			}
			@include media('phone') {
				width: 100%;
			}
			p {
				font-size: 28px;
				@include media('phone') {
					font-size: 22px;
				}
			}
		}
		ul.categories {
			@include listReset;
			display: flex;
			flex-flow: row wrap;
			margin-top: 60px;
			padding-top: 60px;
			position: relative;
			@include media('phone') {
				margin-top: 30px;
				padding-top: 30px;
			}
			&:before {
				content: 'Category';
				display: block;
				width: 100%;
				font-family: 'Soehne', sans-serif;
				font-size: 22px;
				font-weight: 800;
				letter-spacing: -0.035em;
			}
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				width: 20vw;
				max-width: 200px;
				height: 1px;
				background-color: #707070;
			}
			li {
				@include listReset;
				font-size: 58px;
				@include media('tablet') {
					font-size: 38px;
				}
				@include media('phone') {
					font-size: 28px;
				}
				&:not(:last-child) {
					&:after {
						content: '/';
						display: inline-flex;
						margin: 0 5px;
					}
				}
			}
		}
	}
}

/* Modules */

div.modules__layout {
	&.--b {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 140px 60px;
		padding: 0 30px;
		@include media('tablet-portrait') {
			grid-template-columns: 1fr;
		}
		@include media('phone') {
			grid-template-columns: 1fr;
			grid-gap: 80px 20px;
			padding: 0 20px;
		}
		> div.module {
			&:first-child {
				&.--text {
					div.text {
						position: relative;
						text-indent: 100px;
						@include media('phone') {
							text-indent: 60px;
						}
						&:before {
							content: 'Hi*';
							position: absolute;
							top: 10px;
							left: -100px;
							font-family: 'Soehne', sans-serif;
							font-size: 22px;
							font-weight: 800;
							letter-spacing: -0.035em;
							@include media('phone') {
								font-size: 18px;
								top: 3px;
								left: -60px;
							}
						}
					}
				}
			}
			&:last-child {
				&.--image {
					&.--fullbleed {
						[data-ratio] {
							@include media('tablet-and-phone') {
								padding-bottom: 100% !important;
							}
							> img {
								@include media('tablet-and-phone') {
									object-fit: cover;
									object-position: center;
								}
							}
						}
					}
				}
			}
			&.--full {
				grid-column: span 2;
				@include media('tablet-portrait') {
					grid-column: span 1;
				}
				@include media('phone') {
					grid-column: span 1;
				}
			}
			&.--fullbleed {
				grid-column: span 2;
				margin: 0 calc(50% - 50vw);
				@include media('tablet-portrait') {
					grid-column: span 1;
				}
				@include media('phone') {
					grid-column: span 1;
				}
			}
			&.--text {
				div.text {
					p {
						font-size: 38px;
						@include media('tablet') {
							font-size: 28px;
						}
						@include media('phone') {
							font-size: 22px;
						}
					}
					&.--large {
						p {
							font-size: 58px;
							@include media('tablet') {
								font-size: 38px;
							}
							@include media('phone') {
								font-size: 28px;
							}
						}
					}
				}
			}
			&.--image {
				div.image {
					&.--circled {
						@include media('tablet-portrait') {
							width: 60%;
							margin: 0 auto;
						}
						img {
							border-radius: 50%;
						}
					}
				}
			}
		}
	}
}

/* Form */

form.contact {
	input[type="text"],
	input[type="email"],
	input[type="tel"] {
		@include inputReset;
		border-bottom: 1px solid #707070;
		@include placeholder {
			color: black;
		}
	}
	textarea {
		@include inputReset;
		border: 1px solid #707070;
		padding: 10px;
		@include placeholder {
			color: black;
		}
	}
	button[type="submit"] {
		@include inputReset;
		font-family: 'Soehne', sans-serif;
		font-weight: 800;
		font-size: 38px;
		cursor: pointer;
		width: fit-content;
    	text-align: left;
		padding-right: 30px;
		background-image: url('../img/symbol-arrow-submit.svg');
		background-position: right 60%;
		background-repeat: no-repeat;
		background-size: auto 15px;
		transition: padding 250ms;
		@include media('tablet') {
			font-size: 28px;
			padding-right: 25px;
			background-size: auto 13px;
		}
		@include media('phone') {
			font-size: 22px;
			padding-right: 20px;
			background-size: auto 10px;
		}
		&:hover {
			padding-right: 35px;
			@include media('tablet') {
				padding-right: 30px;
			}
			@include media('phone') {
				padding-right: 25px;
			}
		}
		&[disabled] {
			color: black;
			opacity: 0.5;
		}
	}
	div.layout {
		display: grid;
		grid-template-rows: 1fr;
		grid-row-gap: 30px;
	}
}

/* Sliders */

.slider__container {
	position: relative;
	width: 100%;
	overflow: hidden;
	opacity: 0;
	touch-action: manipulation;
	&.swiper-container-initialized {
		opacity: 1;
	}
	&.--horizontal {
		div.slider {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			display: flex;
			flex-flow: row nowrap;
			div.slide {
				display: flex;
				flex-flow: column nowrap;
				justify-content: center;
				align-items: center;
				flex-shrink: 0;
				height: 100%;
				width: 100%;
				overflow: hidden;
				img {
					height: 100%;
					height: calc(100% - 60px);
					width: 100%;
					width: calc(100% - 240px);
					max-width: none;
					object-fit: contain;
					margin: 0 auto;
				}
			}
		}
	}
	&.--v2 {
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
		background-color: #ffffff;
		div.slider {
			height: 100vh;
			height: calc(var(--vh, 1vh) * 100);
			div.slide {
				height: 100vh;
				height: calc(var(--vh, 1vh) * 100);
				width: 100%;
				position: relative;
				img, video {
					position: absolute;
					top: 50%;
					left: 50%;
					height: 100%;
					width: 100%;
					object-fit: cover;
					transform: translate(-50%, -50%);
				}
			}
		}
		div.slider__counter {
			font-size: 38px;
			position: absolute;
			bottom: 30px;
			right: 30px;
			color: white;
			z-index: 1;
			@include media('phone') {
				bottom: 20px;
				right: 20px;
			}
		}
	}
	&.--v3 {
		height: calc(100vh - (190px + ((38px + (20px * 2)) + 50px)));
		height: calc((var(--vh, 1vh) * 100) - (190px + ((38px + (20px * 2)) + 50px)));
		background-color: #ffffff;
		@include media('tablet-portrait') {
			height: auto;
			opacity: 1;
			padding: 0 30px;
		}
		@include media('phone') {
			height: auto;
			opacity: 1;
			padding: 0 20px;
		}
		div.slider {
			height: calc(100vh - (190px + ((38px + (20px * 2)) + 50px)));
			height: calc((var(--vh, 1vh) * 100) - (190px + ((38px + (20px * 2)) + 50px)));
			@include media('tablet-and-phone') {
				height: auto;
				position: relative;
				flex-flow: row wrap;
			}
			div.slide {
				height: calc(100vh - (190px + ((38px + (20px * 2)) + 50px)));
				height: calc((var(--vh, 1vh) * 100) - (190px + ((38px + (20px * 2)) + 50px)));
				width: 100%;
				position: relative;
				@include media('tablet-and-phone') {
					height: auto;
				}
				&:not(:last-child) {
					@include media('tablet-portrait') {
						margin-bottom: 30px;
					}
					@include media('phone') {
						margin-bottom: 20px;
					}
				}
				img, video {
					position: absolute;
					top: 50%;
					left: 50%;
					height: 100%;
					width: 100%;
					transform: translate(-50%, -50%);
					@include media('tablet-and-phone') {
						position: relative;
						top: 0;
						left: 0;
						transform: none;
					}
				}
			}
		}
	}
}
