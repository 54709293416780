/* Reset */
html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box; }

*, ::after, ::before {
  box-sizing: inherit; }

body {
  margin: 0; }

main {
  display: block; }

blockquote, body, figure, h1, h2, h3, h4, h5, h6, hr, li, ol, p, pre, ul {
  margin: 0;
  padding: 0; }

h1, h2, h3, h4, h5, h6, p {
  margin: 1em 0; }

a {
  background-color: transparent; }

b, strong {
  font-weight: bolder; }

audio, embed, iframe, img, object, video {
  display: block;
  max-width: 100%; }

img {
  border-style: none; }

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button, input {
  overflow: visible; }

button, select {
  text-transform: none; }

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button; }

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
  border-style: none;
  padding: 0; }

[type=button]:-moz-focusring, [type=reset]:-moz-focusring, [type=submit]:-moz-focusring, button:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: .35em .75em .625em; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type=checkbox], [type=radio] {
  box-sizing: border-box;
  padding: 0; }

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto; }

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

/* Media */
/* Resets */
/* Layout */
/* Flex */
/* Grid */
/* Helper classes */
.d\:b {
  display: block; }

.d\:f {
  display: flex; }

.d\:if {
  display: inline-flex; }

.d\:i {
  display: inline; }

.d\:ib {
  display: inline-block; }

.d\:g {
  display: grid; }

.d\:n {
  display: none; }

.show\@xs,
.show\:f\@xs,
.show\:if\@xs,
.show\:i\@xs,
.show\:ib\@xs,
.show\:g\@xs {
  display: none !important; }

.show\@sm,
.show\:f\@sm,
.show\:if\@sm,
.show\:i\@sm,
.show\:ib\@sm,
.show\:g\@sm {
  display: none !important; }

.show\@md,
.show\:f\@md,
.show\:if\@md,
.show\:i\@md,
.show\:ib\@md,
.show\:g\@md {
  display: none !important; }

.show\@lg,
.show\:f\@lg,
.show\:if\@lg,
.show\:i\@lg,
.show\:ib\@lg,
.show\:g\@lg {
  display: none !important; }

.show\@print,
.show\:f\@print,
.show\:if\@print,
.show\:i\@print,
.show\:ib\@print,
.show\:g\@print {
  display: none !important; }

@media screen and (max-width: 767px) {
  .show\@xs {
    display: block !important; }
  .show\:f\@xs {
    display: flex !important; }
  .show\:if\@xs {
    display: inline-flex !important; }
  .show\:i\@xs {
    display: inline !important; }
  .show\:ib\@xs {
    display: inline-block !important; }
  .show\:g\@xs {
    display: grid !important; }
  .hide\@xs {
    display: none !important; } }

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .show\@sm {
    display: block !important; }
  .show\:f\@sm {
    display: flex !important; }
  .show\:if\@sm {
    display: inline-flex !important; }
  .show\:i\@sm {
    display: inline !important; }
  .show\:ib\@sm {
    display: inline-block !important; }
  .show\:g\@sm {
    display: grid !important; }
  .hide\@sm {
    display: none !important; } }

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .show\@md {
    display: block !important; }
  .show\:f\@md {
    display: flex !important; }
  .show\:if\@md {
    display: inline-flex !important; }
  .show\:i\@md {
    display: inline !important; }
  .show\:ib\@md {
    display: inline-block !important; }
  .show\:g\@md {
    display: grid !important; }
  .hide\@md {
    display: none !important; } }

@media screen and (min-width: 1201px) and (max-width: 1440px) {
  .show\@lg {
    display: block !important; }
  .show\:f\@lg {
    display: flex !important; }
  .show\:if\@lg {
    display: inline-flex !important; }
  .show\:i\@lg {
    display: inline !important; }
  .show\:ib\@lg {
    display: inline-block !important; }
  .show\:g\@lg {
    display: grid !important; }
  .hide\@lg {
    display: none !important; } }

@media print {
  .show\@print {
    display: block !important; }
  .show\:f\@print {
    display: flex !important; }
  .show\:if\@print {
    display: inline-flex !important; }
  .show\:i\@print {
    display: inline !important; }
  .show\:ib\@print {
    display: inline-block !important; }
  .show\:g\@print {
    display: grid !important; }
  .hide\@print {
    display: none !important; } }

.fs\:1\@xs {
  font-size: 1px !important; }

.fs\:2\@xs {
  font-size: 2px !important; }

@media screen and (min-width: 768px) {
  .fs\:1\@sm {
    font-size: 1px !important; }
  .fs\:2\@sm {
    font-size: 2px !important; } }

@media screen and (min-width: 1024px) {
  .fs\:1\@md {
    font-size: 1px !important; }
  .fs\:2\@md {
    font-size: 2px !important; } }

@media screen and (min-width: 1201px) {
  .fs\:1\@lg {
    font-size: 1px !important; }
  .fs\:2\@lg {
    font-size: 2px !important; } }

.mt\:1\@xs {
  margin-top: 1px !important; }

.pt\:1\@xs {
  padding-top: 1px !important; }

.t\:1\@xs {
  top: 1px !important; }

.mb\:1\@xs {
  margin-bottom: 1px !important; }

.pb\:1\@xs {
  padding-bottom: 1px !important; }

.b\:1\@xs {
  bottom: 1px !important; }

.ml\:1\@xs {
  margin-left: 1px !important; }

.pl\:1\@xs {
  padding-left: 1px !important; }

.l\:1\@xs {
  left: 1px !important; }

.mr\:1\@xs {
  margin-right: 1px !important; }

.pr\:1\@xs {
  padding-right: 1px !important; }

.r\:1\@xs {
  right: 1px !important; }

.mt\:2\@xs {
  margin-top: 2px !important; }

.pt\:2\@xs {
  padding-top: 2px !important; }

.t\:2\@xs {
  top: 2px !important; }

.mb\:2\@xs {
  margin-bottom: 2px !important; }

.pb\:2\@xs {
  padding-bottom: 2px !important; }

.b\:2\@xs {
  bottom: 2px !important; }

.ml\:2\@xs {
  margin-left: 2px !important; }

.pl\:2\@xs {
  padding-left: 2px !important; }

.l\:2\@xs {
  left: 2px !important; }

.mr\:2\@xs {
  margin-right: 2px !important; }

.pr\:2\@xs {
  padding-right: 2px !important; }

.r\:2\@xs {
  right: 2px !important; }

@media screen and (min-width: 768px) {
  .mt\:1\@sm {
    margin-top: 1px !important; }
  .pt\:1\@sm {
    padding-top: 1px !important; }
  .t\:1\@sm {
    top: 1px !important; }
  .mb\:1\@sm {
    margin-bottom: 1px !important; }
  .pb\:1\@sm {
    padding-bottom: 1px !important; }
  .b\:1\@sm {
    bottom: 1px !important; }
  .ml\:1\@sm {
    margin-left: 1px !important; }
  .pl\:1\@sm {
    padding-left: 1px !important; }
  .l\:1\@sm {
    left: 1px !important; }
  .mr\:1\@sm {
    margin-right: 1px !important; }
  .pr\:1\@sm {
    padding-right: 1px !important; }
  .r\:1\@sm {
    right: 1px !important; }
  .mt\:2\@sm {
    margin-top: 2px !important; }
  .pt\:2\@sm {
    padding-top: 2px !important; }
  .t\:2\@sm {
    top: 2px !important; }
  .mb\:2\@sm {
    margin-bottom: 2px !important; }
  .pb\:2\@sm {
    padding-bottom: 2px !important; }
  .b\:2\@sm {
    bottom: 2px !important; }
  .ml\:2\@sm {
    margin-left: 2px !important; }
  .pl\:2\@sm {
    padding-left: 2px !important; }
  .l\:2\@sm {
    left: 2px !important; }
  .mr\:2\@sm {
    margin-right: 2px !important; }
  .pr\:2\@sm {
    padding-right: 2px !important; }
  .r\:2\@sm {
    right: 2px !important; } }

@media screen and (min-width: 1024px) {
  .mt\:1\@md {
    margin-top: 1px !important; }
  .pt\:1\@md {
    padding-top: 1px !important; }
  .t\:1\@md {
    top: 1px !important; }
  .mb\:1\@md {
    margin-bottom: 1px !important; }
  .pb\:1\@md {
    padding-bottom: 1px !important; }
  .b\:1\@md {
    bottom: 1px !important; }
  .ml\:1\@md {
    margin-left: 1px !important; }
  .pl\:1\@md {
    padding-left: 1px !important; }
  .l\:1\@md {
    left: 1px !important; }
  .mr\:1\@md {
    margin-right: 1px !important; }
  .pr\:1\@md {
    padding-right: 1px !important; }
  .r\:1\@md {
    right: 1px !important; }
  .mt\:2\@md {
    margin-top: 2px !important; }
  .pt\:2\@md {
    padding-top: 2px !important; }
  .t\:2\@md {
    top: 2px !important; }
  .mb\:2\@md {
    margin-bottom: 2px !important; }
  .pb\:2\@md {
    padding-bottom: 2px !important; }
  .b\:2\@md {
    bottom: 2px !important; }
  .ml\:2\@md {
    margin-left: 2px !important; }
  .pl\:2\@md {
    padding-left: 2px !important; }
  .l\:2\@md {
    left: 2px !important; }
  .mr\:2\@md {
    margin-right: 2px !important; }
  .pr\:2\@md {
    padding-right: 2px !important; }
  .r\:2\@md {
    right: 2px !important; } }

@media screen and (min-width: 1201px) {
  .mt\:1\@lg {
    margin-top: 1px !important; }
  .pt\:1\@lg {
    padding-top: 1px !important; }
  .t\:1\@lg {
    top: 1px !important; }
  .mb\:1\@lg {
    margin-bottom: 1px !important; }
  .pb\:1\@lg {
    padding-bottom: 1px !important; }
  .b\:1\@lg {
    bottom: 1px !important; }
  .ml\:1\@lg {
    margin-left: 1px !important; }
  .pl\:1\@lg {
    padding-left: 1px !important; }
  .l\:1\@lg {
    left: 1px !important; }
  .mr\:1\@lg {
    margin-right: 1px !important; }
  .pr\:1\@lg {
    padding-right: 1px !important; }
  .r\:1\@lg {
    right: 1px !important; }
  .mt\:2\@lg {
    margin-top: 2px !important; }
  .pt\:2\@lg {
    padding-top: 2px !important; }
  .t\:2\@lg {
    top: 2px !important; }
  .mb\:2\@lg {
    margin-bottom: 2px !important; }
  .pb\:2\@lg {
    padding-bottom: 2px !important; }
  .b\:2\@lg {
    bottom: 2px !important; }
  .ml\:2\@lg {
    margin-left: 2px !important; }
  .pl\:2\@lg {
    padding-left: 2px !important; }
  .l\:2\@lg {
    left: 2px !important; }
  .mr\:2\@lg {
    margin-right: 2px !important; }
  .pr\:2\@lg {
    padding-right: 2px !important; }
  .r\:2\@lg {
    right: 2px !important; } }

.p\:r\@xs {
  position: relative !important; }

.p\:a\@xs {
  position: absolute !important; }

.p\:f\@xs {
  position: fixed !important; }

.p\:s\@xs {
  position: sticky !important; }

.ac\:fs\@xs {
  align-content: flex-start !important; }

.ai\:fs\@xs {
  align-items: flex-start !important; }

.jc\:fs\@xs {
  justify-content: flex-start !important; }

.ac\:c\@xs {
  align-content: center !important; }

.ai\:c\@xs {
  align-items: center !important; }

.jc\:c\@xs {
  justify-content: center !important; }

.ac\:fe\@xs {
  align-content: flex-end !important; }

.ai\:fe\@xs {
  align-items: flex-end !important; }

.jc\:fe\@xs {
  justify-content: flex-end !important; }

.ac\:sa\@xs {
  align-content: space-around !important; }

.jc\:sa\@xs {
  justify-content: space-around !important; }

.ac\:sb\@xs {
  align-content: space-between !important; }

.jc\:sb\@xs {
  justify-content: space-between !important; }

.ac\:se\@xs {
  align-content: space-evenly !important; }

.jc\:se\@xs {
  justify-content: space-evenly !important; }

@media screen and (min-width: 768px) {
  .p\:r\@sm {
    position: relative !important; }
  .p\:a\@sm {
    position: absolute !important; }
  .p\:f\@sm {
    position: fixed !important; }
  .p\:s\@sm {
    position: sticky !important; }
  .ac\:fs\@sm {
    align-content: flex-start !important; }
  .ai\:fs\@sm {
    align-items: flex-start !important; }
  .jc\:fs\@sm {
    justify-content: flex-start !important; }
  .ac\:c\@sm {
    align-content: center !important; }
  .ai\:c\@sm {
    align-items: center !important; }
  .jc\:c\@sm {
    justify-content: center !important; }
  .ac\:fe\@sm {
    align-content: flex-end !important; }
  .ai\:fe\@sm {
    align-items: flex-end !important; }
  .jc\:fe\@sm {
    justify-content: flex-end !important; }
  .ac\:sa\@sm {
    align-content: space-around !important; }
  .jc\:sa\@sm {
    justify-content: space-around !important; }
  .ac\:sb\@sm {
    align-content: space-between !important; }
  .jc\:sb\@sm {
    justify-content: space-between !important; }
  .ac\:se\@sm {
    align-content: space-evenly !important; }
  .jc\:se\@sm {
    justify-content: space-evenly !important; } }

@media screen and (min-width: 1024px) {
  .p\:r\@md {
    position: relative !important; }
  .p\:a\@md {
    position: absolute !important; }
  .p\:f\@md {
    position: fixed !important; }
  .p\:s\@md {
    position: sticky !important; }
  .ac\:fs\@md {
    align-content: flex-start !important; }
  .ai\:fs\@md {
    align-items: flex-start !important; }
  .jc\:fs\@md {
    justify-content: flex-start !important; }
  .ac\:c\@md {
    align-content: center !important; }
  .ai\:c\@md {
    align-items: center !important; }
  .jc\:c\@md {
    justify-content: center !important; }
  .ac\:fe\@md {
    align-content: flex-end !important; }
  .ai\:fe\@md {
    align-items: flex-end !important; }
  .jc\:fe\@md {
    justify-content: flex-end !important; }
  .ac\:sa\@md {
    align-content: space-around !important; }
  .jc\:sa\@md {
    justify-content: space-around !important; }
  .ac\:sb\@md {
    align-content: space-between !important; }
  .jc\:sb\@md {
    justify-content: space-between !important; }
  .ac\:se\@md {
    align-content: space-evenly !important; }
  .jc\:se\@md {
    justify-content: space-evenly !important; } }

@media screen and (min-width: 1201px) {
  .p\:r\@lg {
    position: relative !important; }
  .p\:a\@lg {
    position: absolute !important; }
  .p\:f\@lg {
    position: fixed !important; }
  .p\:s\@lg {
    position: sticky !important; }
  .ac\:fs\@lg {
    align-content: flex-start !important; }
  .ai\:fs\@lg {
    align-items: flex-start !important; }
  .jc\:fs\@lg {
    justify-content: flex-start !important; }
  .ac\:c\@lg {
    align-content: center !important; }
  .ai\:c\@lg {
    align-items: center !important; }
  .jc\:c\@lg {
    justify-content: center !important; }
  .ac\:fe\@lg {
    align-content: flex-end !important; }
  .ai\:fe\@lg {
    align-items: flex-end !important; }
  .jc\:fe\@lg {
    justify-content: flex-end !important; }
  .ac\:sa\@lg {
    align-content: space-around !important; }
  .jc\:sa\@lg {
    justify-content: space-around !important; }
  .ac\:sb\@lg {
    align-content: space-between !important; }
  .jc\:sb\@lg {
    justify-content: space-between !important; }
  .ac\:se\@lg {
    align-content: space-evenly !important; }
  .jc\:se\@lg {
    justify-content: space-evenly !important; } }

.ta\:l\@xs {
  text-align: left !important; }

.ta\:c\@xs {
  text-align: center !important; }

.ta\:r\@xs {
  text-align: right !important; }

.tt\:u\@xs {
  text-transform: uppercase !important; }

.tt\:l\@xs {
  text-transform: lowercase !important; }

.tt\:n\@xs {
  text-transform: none !important; }

@media screen and (min-width: 768px) {
  .ta\:l\@sm {
    text-align: left !important; }
  .ta\:c\@sm {
    text-align: center !important; }
  .ta\:r\@sm {
    text-align: right !important; }
  .tt\:u\@sm {
    text-transform: uppercase !important; }
  .tt\:l\@sm {
    text-transform: lowercase !important; }
  .tt\:n\@sm {
    text-transform: none !important; } }

@media screen and (min-width: 1024px) {
  .ta\:l\@md {
    text-align: left !important; }
  .ta\:c\@md {
    text-align: center !important; }
  .ta\:r\@md {
    text-align: right !important; }
  .tt\:u\@md {
    text-transform: uppercase !important; }
  .tt\:l\@md {
    text-transform: lowercase !important; }
  .tt\:n\@md {
    text-transform: none !important; } }

@media screen and (min-width: 1201px) {
  .ta\:l\@lg {
    text-align: left !important; }
  .ta\:c\@lg {
    text-align: center !important; }
  .ta\:r\@lg {
    text-align: right !important; }
  .tt\:u\@lg {
    text-transform: uppercase !important; }
  .tt\:l\@lg {
    text-transform: lowercase !important; }
  .tt\:n\@lg {
    text-transform: none !important; } }

/* Webfonts */
@font-face {
  font-family: 'Soehne';
  src: url("../webfonts/soehne-web-halbfett.woff2") format("woff2"), url("../webfonts/soehne-web-halbfett.woff") format("woff");
  font-style: normal;
  font-weight: 600; }

@font-face {
  font-family: 'Soehne';
  src: url("../webfonts/soehne-web-fett.woff2") format("woff2"), url("../webfonts/soehne-web-fett.woff") format("woff");
  font-style: normal;
  font-weight: 800; }

@font-face {
  font-family: 'Signifier';
  src: url("../webfonts/signifier-web-light.woff2") format("woff2"), url("../webfonts/signifier-web-light.woff") format("woff");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'Signifier';
  src: url("../webfonts/signifier-web-light-italic.woff2") format("woff2"), url("../webfonts/signifier-web-light-italic.woff") format("woff");
  font-style: italic;
  font-weight: 300; }

/* Vars */
:root {
  --colorBody: #000000; }

/* Global */
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  font-family: 'Signifier', sans-serif;
  background-color: #ffffff;
  color: #000000;
  font-size: 16px;
  line-height: 1.2; }

a {
  transition: color 250ms; }

a, a:active, a:focus, a:hover {
  color: #000000;
  outline: none;
  text-decoration: none; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  line-height: 1.2em; }

.--loading,
.--loading * {
  cursor: wait !important; }

.--lazy {
  min-height: 1px;
  min-width: 1px;
  opacity: 0;
  transition: opacity 250ms;
  -webkit-backface-visibility: hidden; }

.--lazy.--loaded {
  opacity: 1; }

[data-ratio] {
  display: block;
  position: relative;
  width: 100%;
  background-color: #f7f7f7; }
  [data-ratio].--transparent {
    background-color: transparent; }

[data-ratio] > img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

[x-cloak] {
  display: none !important; }

::-moz-selection {
  color: #000000;
  background-color: #ffff00; }

::selection {
  color: #000000;
  background-color: #ffff00; }

@media screen and (orientation: landscape) {
  [data-orientation="landscape"] {
    display: block; }
  [data-orientation="portrait"] {
    display: none; } }

@media screen and (orientation: portrait) {
  [data-orientation="landscape"] {
    display: none; }
  [data-orientation="portrait"] {
    display: block; } }

div.page > div {
  margin: calc(100vh/3) 0; }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    div.page > div {
      margin: calc((100vh/12) * 5) 0; } }
  body.home div.page > div {
    margin: 0; }
  body.projects-single div.page > div {
    margin: 190px 0 50px 0; }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      body.projects-single div.page > div {
        margin: calc((100vh/12) * 4) 0 30px 0; } }
    @media screen and (max-width: 767px) {
      body.projects-single div.page > div {
        margin: 190px 0 20px 0; } }
  body.contact div.page > div, body.about div.page > div {
    margin-bottom: 0; }

.text.--flex > * {
  margin: 0.5em 0; }

.text > *:first-child {
  margin-top: 0 !important; }

.text > *:last-child {
  margin-bottom: 0 !important; }

.text h4 {
  font-family: 'Soehne', sans-serif;
  font-size: 22px;
  font-weight: 800;
  line-height: 1.1;
  letter-spacing: -0.035em;
  display: inline-flex;
  flex-flow: row wrap;
  align-items: baseline;
  margin-bottom: 60px; }
  @media screen and (max-width: 767px) {
    .text h4 {
      font-size: 18px;
      margin-bottom: 30px; } }
  .text h4:before {
    content: '';
    display: block;
    width: 20vw;
    max-width: 200px;
    height: 1px;
    background-color: #707070;
    margin-right: 10px; }
  .text h4 + p {
    margin-top: 0; }

div.site__overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 70;
  visibility: hidden;
  backdrop-filter: blur(40px); }
  div.site__overlay.--active {
    visibility: visible; }

/* Cursor */
div.cursor {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 666; }
  @media (hover: none) {
    div.cursor {
      display: none !important; } }
  div.cursor span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    div.cursor span.cursor__slider.--horizontal {
      height: 45px;
      width: 104px; }
    div.cursor span.cursor__slider svg {
      fill: black; }
    div.cursor span svg {
      height: auto;
      max-height: 100%;
      width: auto;
      max-width: 100%; }

/* Header */
a.site__title {
  font-family: 'Soehne', sans-serif;
  font-size: clamp(80px, 9.5vw, 190px);
  font-weight: 800;
  letter-spacing: -0.035em;
  line-height: 0.9;
  color: black;
  margin: 0;
  position: fixed;
  top: -0.13em;
  left: 0;
  z-index: 100;
  transition: none; }
  @media screen and (min-width: 1024px) and (max-width: 1200px) {
    a.site__title {
      font-size: clamp(80px, 13vw, 190px); } }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    a.site__title {
      font-size: clamp(80px, 20vw, 190px); } }
  body.home a.site__title {
    color: white;
    mix-blend-mode: difference; }
  body.home a.site__title.--reset {
    color: black;
    mix-blend-mode: normal; }
  body.home a.site__title span {
    visibility: hidden; }

button.nav__trigger {
  background: none;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
  display: block;
  height: 57px;
  width: 57px;
  background-color: black;
  border-radius: 50%;
  position: fixed;
  top: 30px;
  right: 30px;
  cursor: pointer;
  z-index: 100; }
  button.nav__trigger::-webkit-inner-spin-button, button.nav__trigger::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
  button.nav__trigger::-webkit-search-decoration, button.nav__trigger::-webkit-search-cancel-button, button.nav__trigger::-webkit-search-results-button, button.nav__trigger::-webkit-search-results-decoration {
    display: none; }
  button.nav__trigger:-webkit-autofill, button.nav__trigger:-webkit-autofill:hover, button.nav__trigger:-webkit-autofill:focus, button.nav__trigger:-webkit-autofill:active {
    transition: background-color 443556s ease-in-out 0s;
    -webkit-text-fill-color: #000000 !important; }
  button.nav__trigger:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000; }
  button.nav__trigger::-moz-focus-outer {
    border: 0; }
  @media screen and (max-width: 767px) {
    button.nav__trigger {
      height: 35px;
      width: 35px;
      top: 20px;
      right: 20px;
      transform: none; } }
  body.home button.nav__trigger.--reset {
    background-color: black;
    mix-blend-mode: normal; }
  body.home button.nav__trigger.--reset span {
    background-color: white; }
  body.home button.nav__trigger.--loaded {
    z-index: 100; }
  button.nav__trigger.--inactive {
    pointer-events: none; }
  body.home button.nav__trigger {
    z-index: 80;
    background-color: white;
    mix-blend-mode: difference; }
  button.nav__trigger span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    height: 6px;
    width: calc(100% - 20px);
    background-color: white; }
    @media screen and (max-width: 767px) {
      button.nav__trigger span {
        height: 3px;
        width: calc(100% - 13px); } }
    body.home button.nav__trigger span {
      background-color: black; }
    button.nav__trigger span:first-child {
      transform: translate(-50%, -50%) translateY(-6px); }
      @media screen and (max-width: 767px) {
        button.nav__trigger span:first-child {
          transform: translate(-50%, -50%) translateY(-4px); } }
    button.nav__trigger span:last-child {
      transform: translate(-50%, -50%) translateY(6px); }
      @media screen and (max-width: 767px) {
        button.nav__trigger span:last-child {
          transform: translate(-50%, -50%) translateY(3px); } }
  button.nav__trigger.--open span:first-child {
    transform: translate(-50%, -50%) rotate(45deg); }
  button.nav__trigger.--open span:last-child {
    transform: translate(-50%, -50%) rotate(-45deg); }

/* Nav */
nav.main {
  list-style: none;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  right: 0;
  padding: 30px;
  height: 100vh;
  height: calc((var(--vh, 1vh) * 100));
  width: 100%;
  z-index: 75;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: flex-end; }
  nav.main ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    nav.main ul li {
      list-style: none;
      margin: 0;
      padding: 0;
      font-family: 'Soehne', sans-serif;
      font-size: clamp(69px, 7.5vw, 150px);
      font-weight: 800;
      line-height: 1.1;
      letter-spacing: -0.035em;
      visibility: hidden; }
      @media screen and (min-width: 1024px) and (max-width: 1200px) {
        nav.main ul li {
          font-size: clamp(69px, 10vw, 150px); } }
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        nav.main ul li {
          font-size: clamp(69px, 16vw, 150px); } }

/* Home */
div.home__loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  z-index: 90; }

div.home__layout > div {
  position: relative; }
  div.home__layout > div.--top {
    height: 100vh;
    height: calc((var(--vh, 1vh) * 100));
    background-color: white; }
  div.home__layout > div.--bottom {
    background-color: white;
    padding: 25vh 30px 100px 30px; }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      div.home__layout > div.--bottom {
        padding: 100px 30px; } }
    @media screen and (max-width: 767px) {
      div.home__layout > div.--bottom {
        padding: 60px 20px 100px 20px; } }
    div.home__layout > div.--bottom div.text {
      font-size: 68px;
      text-indent: 100px;
      position: relative; }
      @media screen and (min-width: 768px) and (max-width: 1200px) {
        div.home__layout > div.--bottom div.text {
          font-size: 48px; } }
      @media screen and (max-width: 767px) {
        div.home__layout > div.--bottom div.text {
          font-size: 28px;
          text-indent: 60px; } }
      div.home__layout > div.--bottom div.text:before {
        content: 'Hi*';
        position: absolute;
        top: 10px;
        left: -100px;
        font-family: 'Soehne', sans-serif;
        font-size: 22px;
        font-weight: 800;
        letter-spacing: -0.035em; }
        @media screen and (min-width: 768px) and (max-width: 1200px) {
          div.home__layout > div.--bottom div.text:before {
            font-size: 22px;
            top: 4px; } }
        @media screen and (max-width: 767px) {
          div.home__layout > div.--bottom div.text:before {
            font-size: 18px;
            top: 3px;
            left: -60px; } }

/* Contact */
div.contact__layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 100px 60px;
  padding: 0 30px; }
  @media screen and (max-width: 767px) {
    div.contact__layout {
      grid-template-columns: 1fr;
      grid-gap: 60px 20px;
      padding: 0 20px; } }
  div.contact__layout > div {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 100px 60px; }
    @media screen and (max-width: 767px) {
      div.contact__layout > div {
        grid-gap: 60px 20px; } }
    div.contact__layout > div div.text {
      font-size: 48px; }
      @media screen and (min-width: 1024px) and (max-width: 1200px) {
        div.contact__layout > div div.text {
          font-size: 22px; } }
      @media screen and (max-width: 1023px) {
        div.contact__layout > div div.text {
          font-size: 22px; } }

div.map__layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 100px; }
  @media screen and (max-width: 767px) {
    div.map__layout {
      grid-template-columns: 1fr;
      margin-top: 60px; } }
  div.map__layout div.map__canvas {
    height: 0;
    padding-bottom: 100%; }
  div.map__layout img {
    object-fit: cover; }

/* Footer */
footer {
  background-color: white;
  height: 50vh;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  padding: 30px; }
  @media screen and (max-width: 767px) {
    footer {
      padding: 20px; } }
  footer ul.social__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row wrap; }
    @media screen and (max-width: 1023px) {
      footer ul.social__list {
        flex-flow: column nowrap; } }
    footer ul.social__list li {
      list-style: none;
      margin: 0;
      padding: 0;
      font-family: 'Soehne', sans-serif;
      font-size: clamp(45px, 6vw, 110px);
      font-weight: 800;
      letter-spacing: -0.035em; }
      footer ul.social__list li:not(:last-child) {
        margin-right: 0.5em; }
        @media screen and (max-width: 767px) {
          footer ul.social__list li:not(:last-child) {
            margin-right: 0; } }
      footer ul.social__list li a {
        color: black; }

/* Projects */
nav.categories {
  position: fixed;
  top: 30px;
  right: calc(30px + 57px + 30px);
  z-index: 65; }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    nav.categories {
      position: relative;
      right: 0;
      top: 0;
      width: calc(50% + 15px);
      padding: 0 30px;
      margin-bottom: 30px; } }
  @media screen and (max-width: 767px) {
    nav.categories {
      position: relative;
      right: 0;
      top: 0;
      padding: 0 20px;
      margin-bottom: 20px; } }
  nav.categories.--active {
    z-index: 80; }
    nav.categories.--active > button:after {
      transform: translateY(-50%) rotate(180deg); }
  nav.categories > button {
    background: none;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    resize: none;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 57px;
    font-family: 'Soehne', sans-serif;
    font-size: 38px;
    font-weight: 600;
    position: relative;
    width: 100%;
    min-width: 280px;
    padding-right: 100px;
    border-bottom: 2px solid black;
    cursor: pointer; }
    nav.categories > button::-webkit-inner-spin-button, nav.categories > button::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; }
    nav.categories > button::-webkit-search-decoration, nav.categories > button::-webkit-search-cancel-button, nav.categories > button::-webkit-search-results-button, nav.categories > button::-webkit-search-results-decoration {
      display: none; }
    nav.categories > button:-webkit-autofill, nav.categories > button:-webkit-autofill:hover, nav.categories > button:-webkit-autofill:focus, nav.categories > button:-webkit-autofill:active {
      transition: background-color 443556s ease-in-out 0s;
      -webkit-text-fill-color: #000000 !important; }
    nav.categories > button:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000; }
    nav.categories > button::-moz-focus-outer {
      border: 0; }
    @media screen and (min-width: 768px) and (max-width: 1200px) {
      nav.categories > button {
        font-size: 28px; } }
    @media screen and (max-width: 767px) {
      nav.categories > button {
        height: 50px;
        font-size: 22px; } }
    nav.categories > button:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      display: block;
      height: 0;
      width: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 13px solid black; }
  nav.categories ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    @media screen and (max-width: 767px) {
      nav.categories ul {
        position: absolute;
        left: 0;
        width: 100%;
        padding: 0 20px; } }
    nav.categories ul li {
      list-style: none;
      margin: 0;
      padding: 0;
      font-family: 'Soehne', sans-serif;
      font-size: 38px;
      font-weight: 600;
      padding: 5px 0;
      min-width: 280px; }
      @media screen and (min-width: 768px) and (max-width: 1200px) {
        nav.categories ul li {
          font-size: 28px; } }
      @media screen and (max-width: 767px) {
        nav.categories ul li {
          font-size: 22px; } }

div.projects__layout {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 100px 30px;
  padding: 0 30px; }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    div.projects__layout {
      grid-template-columns: repeat(2, 1fr); } }
  @media screen and (max-width: 767px) {
    div.projects__layout {
      grid-template-columns: 1fr;
      grid-gap: 20px 20px;
      padding: 0 20px; } }
  div.projects__layout.--filtered > div.project.--doubled {
    grid-column: span 1; }
  div.projects__layout > div.project[data-alignment-y="bottom"] {
    align-self: end; }
  div.projects__layout > div.project.--doubled {
    grid-column: span 2; }
    @media screen and (min-width: 768px) and (max-width: 1200px) {
      div.projects__layout > div.project.--doubled {
        grid-column: span 1; } }
    @media screen and (max-width: 767px) {
      div.projects__layout > div.project.--doubled {
        grid-column: span 1; } }
  div.projects__layout > div.project div.title__categories h1 {
    font-size: 28px;
    margin: 0; }
    @media screen and (max-width: 1023px) {
      div.projects__layout > div.project div.title__categories h1 {
        font-size: 22px; } }
  div.projects__layout > div.project div.title__categories ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row wrap; }
    div.projects__layout > div.project div.title__categories ul li {
      list-style: none;
      margin: 0;
      padding: 0;
      font-family: 'Soehne', sans-serif;
      font-size: 22px;
      font-weight: 600; }
      div.projects__layout > div.project div.title__categories ul li:not(:last-child):after {
        content: '/';
        display: inline-flex;
        margin: 0 5px; }
  div.projects__layout > div.project a.media {
    display: block;
    margin-top: 15px; }

div.project__layout div.title__info {
  background-color: white;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: calc(38px + (20px * 2));
  padding: 0 30px; }
  div.project__layout div.title__info.--reset {
    background-color: transparent; }
    div.project__layout div.title__info.--reset button.info__trigger {
      position: relative;
      z-index: 80; }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    div.project__layout div.title__info {
      flex-flow: row wrap;
      height: auto; } }
  @media screen and (max-width: 767px) {
    div.project__layout div.title__info {
      flex-flow: row wrap;
      height: auto;
      padding: 0 20px; } }
  div.project__layout div.title__info button.info__trigger {
    background: none;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    resize: none;
    font-size: 28px;
    cursor: pointer; }
    div.project__layout div.title__info button.info__trigger::-webkit-inner-spin-button, div.project__layout div.title__info button.info__trigger::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; }
    div.project__layout div.title__info button.info__trigger::-webkit-search-decoration, div.project__layout div.title__info button.info__trigger::-webkit-search-cancel-button, div.project__layout div.title__info button.info__trigger::-webkit-search-results-button, div.project__layout div.title__info button.info__trigger::-webkit-search-results-decoration {
      display: none; }
    div.project__layout div.title__info button.info__trigger:-webkit-autofill, div.project__layout div.title__info button.info__trigger:-webkit-autofill:hover, div.project__layout div.title__info button.info__trigger:-webkit-autofill:focus, div.project__layout div.title__info button.info__trigger:-webkit-autofill:active {
      transition: background-color 443556s ease-in-out 0s;
      -webkit-text-fill-color: #000000 !important; }
    div.project__layout div.title__info button.info__trigger:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000; }
    div.project__layout div.title__info button.info__trigger::-moz-focus-outer {
      border: 0; }
    @media screen and (max-width: 1023px) {
      div.project__layout div.title__info button.info__trigger {
        font-size: 22px;
        margin: 10px 0; } }
  div.project__layout div.title__info h1 {
    font-size: 28px;
    margin: 0;
    width: 50%;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: absolute;
    left: 50%;
    transform: translateX(-50%); }
    @media screen and (max-width: 1023px) {
      div.project__layout div.title__info h1 {
        font-size: 22px;
        width: 100%;
        text-align: left;
        white-space: normal;
        text-overflow: inherit;
        position: relative;
        left: 0;
        transform: none; } }
  div.project__layout div.title__info div.slider__counter {
    font-size: 28px; }

div.project__layout div.info__layout {
  padding: 30px;
  position: absolute;
  height: calc(100vh - (190px + ((38px + (20px * 2)))));
  height: calc((var(--vh, 1vh) * 100) - (190px + ((38px + (20px * 2)))));
  width: 100%;
  overflow-y: auto;
  z-index: 80; }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    div.project__layout div.info__layout {
      height: auto; } }
  @media screen and (max-width: 767px) {
    div.project__layout div.info__layout {
      padding: 20px; } }
  div.project__layout div.info__layout div.text {
    width: 50%; }
    @media screen and (min-width: 1024px) and (max-width: 1200px) {
      div.project__layout div.info__layout div.text {
        width: 75%; } }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      div.project__layout div.info__layout div.text {
        width: 100%; } }
    @media screen and (max-width: 767px) {
      div.project__layout div.info__layout div.text {
        width: 100%; } }
    div.project__layout div.info__layout div.text p {
      font-size: 28px; }
      @media screen and (max-width: 767px) {
        div.project__layout div.info__layout div.text p {
          font-size: 22px; } }
  div.project__layout div.info__layout ul.categories {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    margin-top: 60px;
    padding-top: 60px;
    position: relative; }
    @media screen and (max-width: 767px) {
      div.project__layout div.info__layout ul.categories {
        margin-top: 30px;
        padding-top: 30px; } }
    div.project__layout div.info__layout ul.categories:before {
      content: 'Category';
      display: block;
      width: 100%;
      font-family: 'Soehne', sans-serif;
      font-size: 22px;
      font-weight: 800;
      letter-spacing: -0.035em; }
    div.project__layout div.info__layout ul.categories:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 20vw;
      max-width: 200px;
      height: 1px;
      background-color: #707070; }
    div.project__layout div.info__layout ul.categories li {
      list-style: none;
      margin: 0;
      padding: 0;
      font-size: 58px; }
      @media screen and (min-width: 768px) and (max-width: 1200px) {
        div.project__layout div.info__layout ul.categories li {
          font-size: 38px; } }
      @media screen and (max-width: 767px) {
        div.project__layout div.info__layout ul.categories li {
          font-size: 28px; } }
      div.project__layout div.info__layout ul.categories li:not(:last-child):after {
        content: '/';
        display: inline-flex;
        margin: 0 5px; }

/* Modules */
div.modules__layout.--b {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 140px 60px;
  padding: 0 30px; }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    div.modules__layout.--b {
      grid-template-columns: 1fr; } }
  @media screen and (max-width: 767px) {
    div.modules__layout.--b {
      grid-template-columns: 1fr;
      grid-gap: 80px 20px;
      padding: 0 20px; } }
  div.modules__layout.--b > div.module:first-child.--text div.text {
    position: relative;
    text-indent: 100px; }
    @media screen and (max-width: 767px) {
      div.modules__layout.--b > div.module:first-child.--text div.text {
        text-indent: 60px; } }
    div.modules__layout.--b > div.module:first-child.--text div.text:before {
      content: 'Hi*';
      position: absolute;
      top: 10px;
      left: -100px;
      font-family: 'Soehne', sans-serif;
      font-size: 22px;
      font-weight: 800;
      letter-spacing: -0.035em; }
      @media screen and (max-width: 767px) {
        div.modules__layout.--b > div.module:first-child.--text div.text:before {
          font-size: 18px;
          top: 3px;
          left: -60px; } }
  @media screen and (max-width: 1023px) {
    div.modules__layout.--b > div.module:last-child.--image.--fullbleed [data-ratio] {
      padding-bottom: 100% !important; } }
  @media screen and (max-width: 1023px) {
    div.modules__layout.--b > div.module:last-child.--image.--fullbleed [data-ratio] > img {
      object-fit: cover;
      object-position: center; } }
  div.modules__layout.--b > div.module.--full {
    grid-column: span 2; }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      div.modules__layout.--b > div.module.--full {
        grid-column: span 1; } }
    @media screen and (max-width: 767px) {
      div.modules__layout.--b > div.module.--full {
        grid-column: span 1; } }
  div.modules__layout.--b > div.module.--fullbleed {
    grid-column: span 2;
    margin: 0 calc(50% - 50vw); }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      div.modules__layout.--b > div.module.--fullbleed {
        grid-column: span 1; } }
    @media screen and (max-width: 767px) {
      div.modules__layout.--b > div.module.--fullbleed {
        grid-column: span 1; } }
  div.modules__layout.--b > div.module.--text div.text p {
    font-size: 38px; }
    @media screen and (min-width: 768px) and (max-width: 1200px) {
      div.modules__layout.--b > div.module.--text div.text p {
        font-size: 28px; } }
    @media screen and (max-width: 767px) {
      div.modules__layout.--b > div.module.--text div.text p {
        font-size: 22px; } }
  div.modules__layout.--b > div.module.--text div.text.--large p {
    font-size: 58px; }
    @media screen and (min-width: 768px) and (max-width: 1200px) {
      div.modules__layout.--b > div.module.--text div.text.--large p {
        font-size: 38px; } }
    @media screen and (max-width: 767px) {
      div.modules__layout.--b > div.module.--text div.text.--large p {
        font-size: 28px; } }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    div.modules__layout.--b > div.module.--image div.image.--circled {
      width: 60%;
      margin: 0 auto; } }
  div.modules__layout.--b > div.module.--image div.image.--circled img {
    border-radius: 50%; }

/* Form */
form.contact input[type="text"],
form.contact input[type="email"],
form.contact input[type="tel"] {
  background: none;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
  border-bottom: 1px solid #707070; }
  form.contact input[type="text"]::-webkit-inner-spin-button, form.contact input[type="text"]::-webkit-outer-spin-button,
  form.contact input[type="email"]::-webkit-inner-spin-button,
  form.contact input[type="email"]::-webkit-outer-spin-button,
  form.contact input[type="tel"]::-webkit-inner-spin-button,
  form.contact input[type="tel"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
  form.contact input[type="text"]::-webkit-search-decoration, form.contact input[type="text"]::-webkit-search-cancel-button, form.contact input[type="text"]::-webkit-search-results-button, form.contact input[type="text"]::-webkit-search-results-decoration,
  form.contact input[type="email"]::-webkit-search-decoration,
  form.contact input[type="email"]::-webkit-search-cancel-button,
  form.contact input[type="email"]::-webkit-search-results-button,
  form.contact input[type="email"]::-webkit-search-results-decoration,
  form.contact input[type="tel"]::-webkit-search-decoration,
  form.contact input[type="tel"]::-webkit-search-cancel-button,
  form.contact input[type="tel"]::-webkit-search-results-button,
  form.contact input[type="tel"]::-webkit-search-results-decoration {
    display: none; }
  form.contact input[type="text"]:-webkit-autofill, form.contact input[type="text"]:-webkit-autofill:hover, form.contact input[type="text"]:-webkit-autofill:focus, form.contact input[type="text"]:-webkit-autofill:active,
  form.contact input[type="email"]:-webkit-autofill,
  form.contact input[type="email"]:-webkit-autofill:hover,
  form.contact input[type="email"]:-webkit-autofill:focus,
  form.contact input[type="email"]:-webkit-autofill:active,
  form.contact input[type="tel"]:-webkit-autofill,
  form.contact input[type="tel"]:-webkit-autofill:hover,
  form.contact input[type="tel"]:-webkit-autofill:focus,
  form.contact input[type="tel"]:-webkit-autofill:active {
    transition: background-color 443556s ease-in-out 0s;
    -webkit-text-fill-color: #000000 !important; }
  form.contact input[type="text"]:-moz-focusring,
  form.contact input[type="email"]:-moz-focusring,
  form.contact input[type="tel"]:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000; }
  form.contact input[type="text"]::-moz-focus-outer,
  form.contact input[type="email"]::-moz-focus-outer,
  form.contact input[type="tel"]::-moz-focus-outer {
    border: 0; }
  form.contact input[type="text"]::-webkit-input-placeholder,
  form.contact input[type="email"]::-webkit-input-placeholder,
  form.contact input[type="tel"]::-webkit-input-placeholder {
    color: black; }
  form.contact input[type="text"]:-moz-placeholder,
  form.contact input[type="email"]:-moz-placeholder,
  form.contact input[type="tel"]:-moz-placeholder {
    color: black; }
  form.contact input[type="text"]::-moz-placeholder,
  form.contact input[type="email"]::-moz-placeholder,
  form.contact input[type="tel"]::-moz-placeholder {
    color: black; }
  form.contact input[type="text"]:-ms-input-placeholder,
  form.contact input[type="email"]:-ms-input-placeholder,
  form.contact input[type="tel"]:-ms-input-placeholder {
    color: black; }

form.contact textarea {
  background: none;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
  border: 1px solid #707070;
  padding: 10px; }
  form.contact textarea::-webkit-inner-spin-button, form.contact textarea::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
  form.contact textarea::-webkit-search-decoration, form.contact textarea::-webkit-search-cancel-button, form.contact textarea::-webkit-search-results-button, form.contact textarea::-webkit-search-results-decoration {
    display: none; }
  form.contact textarea:-webkit-autofill, form.contact textarea:-webkit-autofill:hover, form.contact textarea:-webkit-autofill:focus, form.contact textarea:-webkit-autofill:active {
    transition: background-color 443556s ease-in-out 0s;
    -webkit-text-fill-color: #000000 !important; }
  form.contact textarea:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000; }
  form.contact textarea::-moz-focus-outer {
    border: 0; }
  form.contact textarea::-webkit-input-placeholder {
    color: black; }
  form.contact textarea:-moz-placeholder {
    color: black; }
  form.contact textarea::-moz-placeholder {
    color: black; }
  form.contact textarea:-ms-input-placeholder {
    color: black; }

form.contact button[type="submit"] {
  background: none;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
  font-family: 'Soehne', sans-serif;
  font-weight: 800;
  font-size: 38px;
  cursor: pointer;
  width: fit-content;
  text-align: left;
  padding-right: 30px;
  background-image: url("../img/symbol-arrow-submit.svg");
  background-position: right 60%;
  background-repeat: no-repeat;
  background-size: auto 15px;
  transition: padding 250ms; }
  form.contact button[type="submit"]::-webkit-inner-spin-button, form.contact button[type="submit"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
  form.contact button[type="submit"]::-webkit-search-decoration, form.contact button[type="submit"]::-webkit-search-cancel-button, form.contact button[type="submit"]::-webkit-search-results-button, form.contact button[type="submit"]::-webkit-search-results-decoration {
    display: none; }
  form.contact button[type="submit"]:-webkit-autofill, form.contact button[type="submit"]:-webkit-autofill:hover, form.contact button[type="submit"]:-webkit-autofill:focus, form.contact button[type="submit"]:-webkit-autofill:active {
    transition: background-color 443556s ease-in-out 0s;
    -webkit-text-fill-color: #000000 !important; }
  form.contact button[type="submit"]:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000; }
  form.contact button[type="submit"]::-moz-focus-outer {
    border: 0; }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    form.contact button[type="submit"] {
      font-size: 28px;
      padding-right: 25px;
      background-size: auto 13px; } }
  @media screen and (max-width: 767px) {
    form.contact button[type="submit"] {
      font-size: 22px;
      padding-right: 20px;
      background-size: auto 10px; } }
  form.contact button[type="submit"]:hover {
    padding-right: 35px; }
    @media screen and (min-width: 768px) and (max-width: 1200px) {
      form.contact button[type="submit"]:hover {
        padding-right: 30px; } }
    @media screen and (max-width: 767px) {
      form.contact button[type="submit"]:hover {
        padding-right: 25px; } }
  form.contact button[type="submit"][disabled] {
    color: black;
    opacity: 0.5; }

form.contact div.layout {
  display: grid;
  grid-template-rows: 1fr;
  grid-row-gap: 30px; }

/* Sliders */
.slider__container {
  position: relative;
  width: 100%;
  overflow: hidden;
  opacity: 0;
  touch-action: manipulation; }
  .slider__container.swiper-container-initialized {
    opacity: 1; }
  .slider__container.--horizontal div.slider {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: row nowrap; }
    .slider__container.--horizontal div.slider div.slide {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      height: 100%;
      width: 100%;
      overflow: hidden; }
      .slider__container.--horizontal div.slider div.slide img {
        height: 100%;
        height: calc(100% - 60px);
        width: 100%;
        width: calc(100% - 240px);
        max-width: none;
        object-fit: contain;
        margin: 0 auto; }
  .slider__container.--v2 {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background-color: #ffffff; }
    .slider__container.--v2 div.slider {
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100); }
      .slider__container.--v2 div.slider div.slide {
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        width: 100%;
        position: relative; }
        .slider__container.--v2 div.slider div.slide img, .slider__container.--v2 div.slider div.slide video {
          position: absolute;
          top: 50%;
          left: 50%;
          height: 100%;
          width: 100%;
          object-fit: cover;
          transform: translate(-50%, -50%); }
    .slider__container.--v2 div.slider__counter {
      font-size: 38px;
      position: absolute;
      bottom: 30px;
      right: 30px;
      color: white;
      z-index: 1; }
      @media screen and (max-width: 767px) {
        .slider__container.--v2 div.slider__counter {
          bottom: 20px;
          right: 20px; } }
  .slider__container.--v3 {
    height: calc(100vh - (190px + ((38px + (20px * 2)) + 50px)));
    height: calc((var(--vh, 1vh) * 100) - (190px + ((38px + (20px * 2)) + 50px)));
    background-color: #ffffff; }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      .slider__container.--v3 {
        height: auto;
        opacity: 1;
        padding: 0 30px; } }
    @media screen and (max-width: 767px) {
      .slider__container.--v3 {
        height: auto;
        opacity: 1;
        padding: 0 20px; } }
    .slider__container.--v3 div.slider {
      height: calc(100vh - (190px + ((38px + (20px * 2)) + 50px)));
      height: calc((var(--vh, 1vh) * 100) - (190px + ((38px + (20px * 2)) + 50px))); }
      @media screen and (max-width: 1023px) {
        .slider__container.--v3 div.slider {
          height: auto;
          position: relative;
          flex-flow: row wrap; } }
      .slider__container.--v3 div.slider div.slide {
        height: calc(100vh - (190px + ((38px + (20px * 2)) + 50px)));
        height: calc((var(--vh, 1vh) * 100) - (190px + ((38px + (20px * 2)) + 50px)));
        width: 100%;
        position: relative; }
        @media screen and (max-width: 1023px) {
          .slider__container.--v3 div.slider div.slide {
            height: auto; } }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          .slider__container.--v3 div.slider div.slide:not(:last-child) {
            margin-bottom: 30px; } }
        @media screen and (max-width: 767px) {
          .slider__container.--v3 div.slider div.slide:not(:last-child) {
            margin-bottom: 20px; } }
        .slider__container.--v3 div.slider div.slide img, .slider__container.--v3 div.slider div.slide video {
          position: absolute;
          top: 50%;
          left: 50%;
          height: 100%;
          width: 100%;
          transform: translate(-50%, -50%); }
          @media screen and (max-width: 1023px) {
            .slider__container.--v3 div.slider div.slide img, .slider__container.--v3 div.slider div.slide video {
              position: relative;
              top: 0;
              left: 0;
              transform: none; } }
